import React from "react";
import Calendar from "@ericz1803/react-google-calendar";
import bgi from './assets/calendarpage04.png';

const API_KEY = "AIzaSyBJBFGV_B79wBHEPeryzt59ScHIBUjFn6k";
let calendars = [
  {calendarId: "localandlivecalendar@gmail.com"},
  // {
  //   calendarId: "localandlivecalendar@gmail.com",
  //   color: "#101729" //optional, specify color of calendar 2 events
  // }
];

class App extends React.Component {
  render() {
    return (
      <div className="cal" style={{padding: '2%', fontFamily: 'fantasy'}}>
        <h1 style={{color: 'white', textAlign: 'center'}}>Cincy Local and Live Calendar</h1>
        <Calendar apiKey={API_KEY} calendars={calendars} />
      </div>
    )
  }
}

export default App;